<template>
  <div>
    <v-btn
      dark
      width="100%"
      height="75px"
      color="#CC0000"
      class="text-capitalize mb-6"
      v-if="$vuetify.breakpoint.smAndUp"
      @click="goToWizFitChallenge()"
    >
      <span
        style="
          font-family: Roboto Slab;
          font-size: 24px;
          letter-spacing: 0px;
          font-weight: 600;
        "
        >Register A Student</span
      >
    </v-btn>
    <div class="pb-6 pt-0">
      <v-card>
        <v-card-title class="pb-0 pt-2" v-if="$vuetify.breakpoint.smAndUp">
          <v-row>
            <v-col cols="12" class="text-center px-4">
              <p class="cardHeading">{{ this.fundRaiseStatus }}</p>
              <img
                style="margin-top: -18px"
                width="100%"
                :src="this.fundRaisePercentageImg"
                alt=""
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-title v-if="$vuetify.breakpoint.xsOnly" style="height: 80px">
          <v-btn
            dark
            width="100%"
            height="50px"
            color="#CC0000"
            class="text-capitalize mb-6"
            @click="goToWizFitChallenge()"
          >
            <span
              style="
                font-family: Roboto Slab;
                font-size: 20px;
                letter-spacing: 0px;
                font-weight: 600;
              "
              >Register A Student</span
            >
          </v-btn>
        </v-card-title>
        <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
        <v-card-text>
          <v-card-title class="pb-0 pt-2" v-if="$vuetify.breakpoint.xsOnly">
            <v-row>
              <v-col cols="12" class="text-center px-4">
                <p class="cardHeading">{{ this.fundRaiseStatus }}</p>
                <img
                  style="margin-top: -18px"
                  width="100%"
                  :src="this.fundRaisePercentageImg"
                  alt=""
                />
              </v-col>
            </v-row>
          </v-card-title>
          <div class="px-2">
            <div style="color: #067605; font-size: 26px">
              ${{ this.totalFundRaise.toLocaleString("en-US") }}
              <span
                style="font-size: 16px; color: #757575"
                v-if="this.totalGoalHalf"
                >raised of ${{ this.totalGoalHalf }} goal</span
              >
            </div>
            <v-progress-linear
              :value="this.fundRaisePercentage"
              color="#4A3290"
              class="my-1"
              height="15"
              style="border-radius: 20px"
            >
              <strong style="color: white"
                >{{ this.fundRaisePercentage }}%</strong
              >
            </v-progress-linear>
            <v-row>
              <v-col cols="5">
                <div style="font-size: 18px; color: #1d1d1d">
                  {{ this.totalDonor }}
                  <span class="cardSpan" style="font-size: 16px">Donors</span>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="5" class="text-right">
                <div style="font-size: 18px; color: #1d1d1d">
                  {{ this.daysLeft }}
                  <span class="cardSpan">days left</span>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { API_DISTRICT_OVERVIEW_ANIMATION } from "@/constants/APIUrls";
export default {
  name: "DonationInfoCardDistrict",
  data() {
    return {
      fundRaisePercentage: "",
      fundRaisePercentageImg: "",
      totalGoal: "",
      totalGoalHalf: "",
      fundRaiseStatus: "",
      totalFundRaise: "",
      totalDonor: "",
      daysLeft: "",
    };
  },
  methods: {
    separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
    },
    getDistrictDetails() {
      const self = this;
      self.loading = true;
      const successHandler = (res) => {
        self.totalFundRaise = res.data.total_fund_raised;
        self.fundRaisePercentage = res.data.fund_raise_percentage;
        self.fundRaisePercentageImg = res.data.fund_raise_percentage_img;
        self.totalGoal = res.data.total_goal;
        self.totalGoalHalf = res.data.total_goal_50;
        self.fundRaiseStatus = res.data.fund_raised_status_message;
        self.totalDonor = res.data.overview_data.total_donor;
        self.daysLeft = res.data.remaining_days;

        self.loading = false;
      };

      const failureHandler = (res) => {
        console.log("Failure ", res);
        self.loading = false;
      };

      let formData = {};
      formData["campaign_id"] = 227;
      formData["district_id"] = 25;
      //formData["campaign_id"] = 228;
      //formData["district_id"] =12;
      Axios.request_GET(
        API_DISTRICT_OVERVIEW_ANIMATION,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    goToWizFitChallenge() {
      window.open("/wizfitchallenge", "_blank");
    },
  },
  computed: {},
  mounted() {
    this.getDistrictDetails();
  },
};
</script>
<style scoped>
.v-card {
  border-top: 12px solid #512e96;
}
.cardHeading {
  color: #512e96;
  font-weight: 600;
  font-family: Roboto Slab;
}
.v-btn {
  border-radius: 6px;
}
.cardSpan {
  font-size: 16px;
  color: #757575;
}
.btnSpan {
  font-family: Roboto Slab;
  font-size: 25px;
  font-weight: 600;
  width: 80%;
  letter-spacing: 0px;
}
.btn.v-btn {
  height: 60px;
}
@media only screen and (max-width: 600px) {
  .v-card {
    border-top: 6px solid #512e96;
  }
  .btnSpan {
    font-family: Roboto Slab;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
    letter-spacing: 0px;
  }
  .btn.v-btn {
    height: 45px;
  }
}
</style>
